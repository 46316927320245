import React from 'react'
import './Testimonials2022Slider.scss'
import Carousel from '../../../Carousel'
import { decodeEntities, slugify } from '../../../../utils/helpers'
import GatsbyLink from '../../../GatsbyLink'
import { ImagePass } from '../../../Image/ImagePass'

/* This is an updated version of the TestimonialSlider component */
/* It is used on the homepage from the 2022 UI/UX audit */
/* Keeping it separate to avoid messing up the other component */

export const Testimonials2022Slider = ({ title, testimonials, image }) => {
  const carouselSettings = {
    container: `${slugify(title)}-slug`,
    nav: true,
    controls: true,
    mouseDrag: true,
    items: 1,
    loop: true,
    mode: 'gallery',
  }
  return (
    <div className="testimonial-slider-22">
      {title && (
        <span className="section-title h3">{decodeEntities(title)}</span>
      )}
      <div className="wrap">
        <div className="testimonial-content">
          <Carousel settings={carouselSettings}>
            {testimonials &&
              Object.keys(testimonials).length > 0 &&
              testimonials?.map((item, index) => {
                return (
                  <div
                    className="slide-container"
                    key={`testimonial-slider__${index}`}
                  >
                    <div className="content">
                      <blockquote
                        dangerouslySetInnerHTML={{
                          __html: decodeEntities(item.content),
                        }}
                      />
                      {(item.acf.name || item.acf.position) && (
                        <figcaption>
                          {item.acf.name && (
                            <strong>{decodeEntities(item.acf.name)} </strong>
                          )}
                          {item.acf.position &&
                            decodeEntities(item.acf.position)}
                        </figcaption>
                      )}
                      {item.acf.projectLink && (
                        <GatsbyLink
                          href={item.acf.projectLink.url}
                          className="action"
                        >
                          View Project
                        </GatsbyLink>
                      )}
                      {/* {item.acf.logo.source_url && (
                        <div className="logo">
                          <ImagePass src={item.acf.logo} />
                        </div>
                      )} */}
                    </div>
                    <div className="testimonial-image">
                      <div className="background">
                        <ImagePass src={item?.acf?.featuredImage} />
                      </div>
                    </div>
                  </div>
                )
              })}
          </Carousel>
        </div>
      </div>
    </div>
  )
}
