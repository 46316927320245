import React from 'react'
import { graphql } from 'gatsby'
import loadable from '@loadable/component'
import Previewable from '../components/Previewable'
import * as AcfLayout from '../components/Acf'
import SEO from '../components/SEO'
import { decodeEntities } from '../utils/helpers'

const loadableComponents = [''] // Non SEO friendly components

const AcfComponent = ({ location, componentName, item, pageContext }) => {
  // Loadable or import comport component
  const ComponentName = loadableComponents.includes(componentName)
    ? loadable(props => import(`../components/Acf/${componentName}/index.jsx`))
    : AcfLayout[componentName]

  // If component does not exist in acf folder, print error message
  const componentTitle = item.__typename
    ? item.__typename.replace('WordPressAcf_', '')
    : 'No name'
  if (!ComponentName) {
    return (
      <section className="page missing">
        <div className="inner">
          The ACF component
          <strong>"{componentTitle}"</strong> is missing.
          <span>&#128540;</span>
        </div>
      </section>
    )
  }
  return (
    <ComponentName location={location} pageContext={pageContext} {...item} />
  )
}

const Page = ({ data, location, pageContext }) => {
  const { wordpressPost: page, site, wordpressWpSettings } = data
  const { wordpressUrl } = wordpressWpSettings
  if (!page) return null
  const { title, yoast, yoast_head, acf = {} } = page
  const { layout } = acf
  const { title: siteTitle } = wordpressWpSettings

  return (
    <>
      <SEO
        title={`${
          yoast.metaTitle
            ? yoast.metaTitle
            : `${decodeEntities(title)} | ${decodeEntities(siteTitle)}`
        }`}
        desc={yoast.metaDescription}
        yoast={yoast}
        location={location}
      />
      {layout &&
        layout.map((item, index) => {
          if (!item.__typename) return null
          const layoutComponentName = item.__typename.replace(
            'WordPressAcf_',
            ''
          )
          return (
            <AcfComponent
              key={index}
              componentName={layoutComponentName}
              item={item}
              location={location}
              pageContext={pageContext}
            />
          )
        })}
    </>
  )
}

export default Previewable(Page, 'page')
// export default (Page);

export const pageQuery = graphql`
  query PageById($id: String!) {
    wordpressWpSettings {
      title
      siteUrl
      wordpressUrl
    }
    wordpressPost: wordpressPage(id: { eq: $id }) {
      title
      content
      wordpress_id
      # yoast_head
      yoast {
        metaTitle: title
        metaDescription: metadesc
        meta_robots_noindex
        meta_robots_nofollow
        opengraph_image {
          source_url
        }
        twitter_image {
          source_url
        }
      }
      acf {
        layout: layout_page {
          __typename
          ...FlexibleBlockQuery
          ...HeaderQuery
          ...TestimonialSliderQuery
          ...BlogFeedQuery
          ...GalleryQuery
          ...StaffProfilesQuery
          ...LogoFeedQuery
          ...LinkListQuery
          ...LinkTilesQuery
          ...TabsQuery
          ...GlobalTrendingQuery
          ...LocationsQuery
          ...BlockCarouselQuery
          ...NewsletterSignupQuery
        }
      }
    }
  }
`
