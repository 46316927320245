import React from 'react'
import { StaticQuery, graphql } from 'gatsby'
import { ImagePass } from '../../Image/ImagePass'
import GatsbyLink from '../../GatsbyLink'
import { RenderShortcode } from '../../RenderShortcode'
import { RenderContent } from '../../RenderContent'
import { decodeEntities } from '../../../utils/helpers'
import './Locations.scss'

const LocationsPass = props => {
  const { data, title } = props
  const { options } = data.siteSettings
  const { locations } = options
  return (
    <div className="locations-section">
      <>
        {title && (
          <div className="header">
            <div className="inner">
              <h2 dangerouslySetInnerHTML={{ __html: title }} />
            </div>
          </div>
        )}
        <section className="locations">
          <div className="three-column-grid">
            <div className="inner">
              {locations &&
                locations.map((location, index) => (
                  <div className="col text" key={index}>
                    <div className="inner-content text-block">
                      <div className="entry-content">
                        <div className="location-image">
                          <ImagePass src={location.locationImage} />
                        </div>
                        {location.locationName && (
                          <h3
                            dangerouslySetInnerHTML={{
                              __html: `${location.locationName} <br/>Showroom`,
                            }}
                          />
                        )}
                        {location.addressDisplay && (
                          <p
                            dangerouslySetInnerHTML={{
                              __html: location.addressDisplay
                                .split(', ')
                                .join('</br>'),
                            }}
                          />
                        )}
                        {location.phone && (
                          <p>
                            <a
                              href={`tel:${location.phone.replace(
                                /[^A-Z0-9]/gi,
                                ''
                              )}`}
                              className="control-call"
                              aria-label="Call Now"
                            >
                              <strong>{location.phone}</strong>
                            </a>
                          </p>
                        )}
                        <p>
                          <a href={`mailto:${decodeEntities(location.email)}`}>
                            <span
                              dangerouslySetInnerHTML={{
                                __html: location.email.replace(
                                  '&#64;',
                                  '</br>&#64;'
                                ),
                              }}
                            />
                          </a>
                        </p>
                        <p>
                          <GatsbyLink
                            to={`${location.contactLink.url}#contact-form`}
                            className="action"
                          >
                            {location.contactLink.title}
                          </GatsbyLink>
                        </p>
                      </div>
                    </div>
                  </div>
                ))}
            </div>
          </div>
        </section>
      </>
    </div>
  )
}

export const Locations = props => {
  return (
    <StaticQuery
      //Look to update this query when dynamic variables become available https://spectrum.chat/gatsby-js/general/using-variables-in-a-staticquery~abee4d1d-6bc4-4202-afb2-38326d91bd05
      query={graphql`
        query {
          siteSettings: wordpressAcfOptions(options: {}) {
            options {
              locations {
                locationName
                addressDisplay
                email
                phone
                locationImage {
                  source_url
                  localFile {
                    childImageSharp {
                      fixed(width: 480) {
                        ...GatsbyImageSharpFixed_withWebp
                      }
                    }
                  }
                }
                contactLink {
                  url
                  title
                }
              }
            }
          }
        }
      `}
      render={data => <LocationsPass {...props} data={data} />}
    />
  )
}
